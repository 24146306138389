*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

#root, html, body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

a {
  cursor: pointer;
}

body {
  background: #181317; /*#1A1A1A;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f1f1f1;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  background-color: #251E26; /*202126;*/
  border-radius: 4px;
  overflow: hidden;
  width: 90%;
  max-width: 750px;
  min-height: 350px;
  display: flex;
  padding: 1.2rem 0px;
}

.login-form {
  width: 60%;
  border-right: 2px dashed #111111cc;
}

.welcome-holder {
  text-align: center;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: .8rem;
}

.welcome-holder h1 {
  font-size: 3.2rem;
}

.welcome-holder p {
  font-size: 1.6rem;
  color: #818181;
}

.login-form form {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
}

.input-holder {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 12px;
}

.input-holder input {
  padding: 1rem;
  background-color: #161416;
  border: none;
  font-size: 1.6rem;
  border-radius: 4px;
  color: #f1f1f1;
}

.input-holder label {
  color: #f1f1f1;
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 1.2rem;
}

.forgot-password {
  margin-bottom: 1.6rem;
}

.forgot-password a {
  color: #F4A8CF;
  text-decoration: none;
}

form button {
  border: none;
  background: #F4A8CF;
  color: #000;
  border-radius: 4px;
  padding: 1.2rem;
  font-size: 1.6rem;
  cursor: pointer;
  margin-bottom: 8px;
}

form button:hover {
  background: #e08bb7;
  transition: .1s ease-in-out;
}

.create-account {
  display: flex;
  gap: 4px;
}

.create-account p, 
.create-account b,
.forgot-password b {
  font-size: 1.2rem;
}

.create-account a {
  color: #F4A8CF;
  text-decoration: none;
}

.login-image {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-image img {
  width: 100%;
}

.tips {
  text-align: center;
  position: relative;
  top: -30px;
}

.tips p, .tips b {
  font-size: 1.2rem;
}

.authorize-container, .logged-container {
  background: #251E26;
  width: 100%;
  max-width: 700px;
  border-radius: 4px;
  padding: 2.4rem 1.6rem;
}

.authorize-header {
  margin-bottom: 2rem;
}

.authorize-header .app-logo-container {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: .8rem;
}


.app-logo-container svg {
  font-size: 2.4rem;
  color: #413b41;
  transition: color 0.3s ease-in-out;
  animation: color-transition 2.5s infinite;
}

@keyframes color-transition {
  0% {
    color: #413b41;
  }
  50% {
    color: #F4A8CF;
  }
  100% {
    color: #413b41;
  }
}

.app-description {
  text-align: center;
}

.app-description p {
  font-size: 1.6rem;
}

.app-logo-holder {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #F4A8CF;
  background: #ffefcc;
}

.app-logo-img {
  width: 100%;
}

.authorize-body {
  width: 100%;
}

.permissions-header {
}

.permissions-header p {
  font-size: 1.2rem;
  padding: .8rem 0;
}

.permissions-body ul {
  list-style: none;
  padding: 0;
  padding: .8rem 0;
  border-top: 1px solid #ebebeb;
}

.allow-item, .deny-item {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px;
  font-size: 1.6rem;
}

.allow-item svg {
  font-size: 2.4rem;
  color: #F4A8CF;
}

.deny-item svg {
  font-size: 2.4rem;
  color: #413b41;
}

.deny-item {
  color: #584f58;
}

.authorize-disclaimer {
  border-top: 1px solid #ebebeb;
  padding: 2rem 0;
  color: #919191;
}

.authorize-disclaimer p {
  font-size: 1.2rem;
}

.authorize-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.authorize-buttons {
  display: flex;
  gap: 8px;
}

.deny-button {
  background: transparent;
  border: none;
  font-size: 1.2rem;
  color: inherit;
}

.deny-button {
  background: transparent;
}

.allow-button {
  background: #F4A8CF;
  color: #000;
  border-radius: 4px;
}

.deny-button, .allow-button {
  cursor: pointer;
  font-size: 1.6rem;
  border: none;
  padding: 8px 24px;
}

.signup-form {
  width: 100%;
}

.signup-form button {
  width: 100%;
  margin-top: .4rem;
  padding: 1rem;
}

.signup-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: .8rem;
  margin-bottom: 1.6rem;
}

.signup-header h1 {
  font-size: 3.2rem;
}

.signup-header p {
  font-size: 1.6rem;
  color: #818181;
}

.logged-container {
  text-align: center;
}

.logged-container h1 {
  font-size: 3.2rem;
  margin-bottom: .8rem;
}

.logged-container p {
  font-size: 1.6rem;
  color: #818181;
}